<template>
  <div class="container">
    <!--begin::Page Layout-->
    <div class="card card-custom">
      <div class="card-header">
        <h3 class="card-title">
          Why this item is better then this of this term
        </h3>
      </div>
      <div class="card-body">
        <form class="form">
          <div class="card-body">
            <div class="form-group">
              <label>Query:</label>
              <input
                type="text"
                name="q"
                v-model="qString"
                class="form-control form-control-solid"
              />
            </div>
            <div class="form-group">
              <label>First #ID:</label>
              <input
                type="text"
                name="a"
                v-model="aString"
                class="form-control form-control-solid"
              />
              <span class="form-text text-muted"
                >The uniq key of item (see in schema), like code etc.</span
              >
            </div>
            <div class="form-group">
              <label>Second #ID:</label>
              <input
                type="text"
                name="b"
                v-model="bString"
                class="form-control form-control-solid"
              />
            </div>

            <div class="form-group">
              <collection_select />
            </div>
          </div>
        </form>
      </div>
      <div class="card-footer">
        <a
          href="#"
          class="btn btn-info"
          ref="submit"
          @click.prevent="loadAction"
          >Load</a
        >
      </div>
    </div>

    <div class="" v-if="!this.compare_result[0] && !this.compare_result[1]">
      We not found your items in this term
    </div>

    <div v-else>
      <div
        class=""
        v-if="
          this.compare_result[0] &&
            this.compare_result[1] &&
            this.compare_result[0].explain.value ===
              this.compare_result[1].explain.value
        "
      >
        In relevance are both items is same. The sort is defined by
      </div>
      <div class="row mt-5">
        <div class="col-5 bg-dark text-white" v-if="this.compare_result[0]">
          <strong>{{ this.compare_result[0].name }}</strong>
          <vue-json-pretty
            :path="'res'"
            :data="this.compare_result[0].explain"
            @click="handleClick"
          ></vue-json-pretty>
        </div>
        <div class="col-2"></div>
        <div class="col-5 bg-dark text-white" v-if="this.compare_result[1]">
          <strong>{{ this.compare_result[1].name }}</strong>
          <vue-json-pretty
            :path="'res'"
            :data="this.compare_result[1].explain"
            @click="handleClick"
          ></vue-json-pretty>
        </div>
        <div v-else-if="this.bString.trim() !== ''">
          Second item not found
        </div>
      </div>
    </div>
    <!--end::Page Layout-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import { COMPARE_RESULTS_MODULE } from "@/core/services/store/compare_results.module";
import collection_select from "@/view/pages/collections/collection_select";

import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

export default {
  components: { collection_select, VueJsonPretty },
  data() {
    return {
      qString: "",
      aString: "",
      bString: ""
    };
  },
  methods: {
    serialize: obj => {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    },
    loadAction() {
      this.$store.dispatch(COMPARE_RESULTS_MODULE, {
        q: this.qString,
        a: this.aString,
        b: this.bString,
        c: this.selectedCollection.id
      });
    },
    reloadData() {}
  },
  watch: {
    changeProject() {
      this.reloadData();
    },
    changeCollection() {
      this.reloadData();
    }
  },
  computed: {
    changeProject() {
      return this.currentProject.token;
    },
    changeCollection() {
      return this.selectedCollection.id;
    },
    ...mapState({
      errors: state => state.compare_results.errors,
      message: state => state.compare_results.message,
      hasMessage: state => state.compare_results.hasMessage,
      loading: state => state.compare_results.loading,
      compare_result: state => state.compare_results.data
    }),
    ...mapGetters(["selectedCollection", "currentProject"])
  },
  beforeCreate() {},
  mounted() {
    this.reloadData();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Compare results" }]);
  }
};
</script>
<style>
.slider {
  /* overwrite slider styles */
  width: 200px;
}
</style>
