<template>
  <b-dropdown
    id="collectionSelect"
    :text="
      this.collectionSelected ? this.collectionSelected.name : 'New collection'
    "
  >
    <b-dropdown-item
      v-for="item in collections"
      :key="item.id"
      @click.prevent="change(item)"
    >
      {{ item.name }}
    </b-dropdown-item>
    <add_dialog v-if="collections.length === 0" />
  </b-dropdown>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import {
  COLLECTION_SELECTED,
  PULL_COLLECTIONS
} from "@/core/services/store/collections.module";
import add_dialog from "@/view/pages/collections/add_dialog";

export default {
  components: { add_dialog },
  watch: {
    changeProject() {
      this.reloadData();
    }
  },
  computed: {
    changeProject() {
      return this.currentProject.token;
    },
    ...mapState({
      errors: state => state.collections.errors,
      message: state => state.collections.message,
      hasMessage: state => state.collections.hasMessage,
      collections: state => state.collections.collections,
      collectionSelected: state => state.collections.collectionSelected
    }),
    ...mapGetters(["currentProject"])
  },
  methods: {
    reloadData() {
      this.$store.dispatch(PULL_COLLECTIONS);
    },
    change: function(item) {
      this.$store.dispatch(COLLECTION_SELECTED, item);
    }
  },
  mounted() {
    this.reloadData();
  }
};
</script>
